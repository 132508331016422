/**
 * 会员中心---融资申请
 */
import service from '../request'

const baseUrl = `${window.globalUrl.HOME_API_HAN}/trade-financing`;
const baseUrl2 = `${window.globalUrl.HOME_API_HAN}/trade-website`;

// 获取我的融资
export const getMyFinanceList = (params) => {
  return service.post(`${baseUrl2}/financing/myFinanceList`, params)
}

// 图片上传接口
export const getUpLoadList = (params) => {
  return service.post(`${baseUrl}/financing/upload`, params)
}

// 融资申请接口
export const getFinanceApplyList = (params) => {
  return service.post(`${baseUrl2}/financing/financeApply`, params)
}

// 为人担保申请接口
export const getApplyList = (params) => {
  return service.post(`${baseUrl2}/financing/guaranteedForApply`, params)
}

// 选择担保人列表接口
export const getZrdbList = (params) => {
  return service.post(`${baseUrl2}/financing/myOptionalGuaranteeList`, params)
}

// 担保贷款人列表接口
export const getWrdbList = (params) => {
  return service.post(`${baseUrl2}/financing/myOptionalLoanersList`, params)
}

// 产权类型列表
export const getTypeList = (params) => {
  return service.get(`${baseUrl}/financing/propertyTypeList?financingType=`+params)
}

// 为人担保--为他担保
export const getGuaranteeList = (params) => {
  return service.post(`${baseUrl}/financing/guarantee`, params)
}

// 找人担保--担保邀请
export const getGuaranteeInviteList = (params) => {
  return service.post(`${baseUrl}/financing/guaranteeInvite`, params)
}

// 首页--融资备案公示列表
export const getNoticeList = (params) => {
  return service.post(`${baseUrl2}/financing/financingNoticeList`,params)
}
